import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { AppContext, clsNames, dConfirm, tSuccess, useUser } from 'unno-comutils'
import { logout } from 'unno-comutils/connect'

import Icon from 'unno-comutils/Icon'

import { MenuGroups, Menus } from './service/menu'
import { PageScroll } from 'unno-comutils/ui'

export default function MainLayout (props: any) {
    const location = useLocation()

    const paths = location.pathname.split('/').filter(i => !!i)
    const cGroup = paths[0]

    const group = MenuGroups.find(i => i.page === cGroup)

    return <div className={`layout ${!!group ? '-' + group.color : '-blue'}`}>
        <SideMenu/>
        <div className="layout-content">
            <div className="_protected">
                {props.children}
            </div>
        </div>
    </div>
}

function SideMenu (props: any) {
    const user = useUser()
    const location = useLocation()
    const history = useHistory()

    const [miniSideMenu, setMiniSideMenu] = useState(localStorage.getItem('UNNO_OFFICE_SIDEMENU_MINI') === '1')

    const menuClick = (e: any, item: any) => {
        if (!e.ctrlKey) {
            const url = !!item.url ? item.url :
                (!!item.group ? `/${item.group}/${item.page}` : `/${item.page}`)
            e.preventDefault()
            history.push(url)
        }
    }

    const toggleMiniSideMenu = () => {
        const mini = !miniSideMenu
        setMiniSideMenu(mini)
        localStorage.setItem('UNNO_OFFICE_SIDEMENU_MINI', mini ? '1' : '')
    }

    const userLogout = (setUser: any) => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                setUser(null)
            })
        })
    }

    const paths = location.pathname.split('/').filter(i => !!i)
    const cMenu = paths[1]

    return <div className={`layout-menu` + (props.grow ? ' -grow' : '') + (miniSideMenu ? ' -mini' : '')}>
        <div className="layout-menuhead">
            <div className="_content">
                <Icon name="car-building" className="_btn _btn-home" button onClick={() => history.push(`/office/work`)}/>
                <span className={'_user'}>{user.name}</span>
                <AppContext.Consumer>
                    {({ setUser }) => <Icon name={'power-off'} className={'_btn ml-auto'} button onClick={() => userLogout(setUser)}/>}
                </AppContext.Consumer>
            </div>
            <div className="_info">
                <span className={'_version'}>Version: {process.env.REACT_APP_VERSION}</span>
            </div>
        </div>

        <div className="layout-menuside">
            <PageScroll>
                {Menus.filter((item: any) => user.roles.indexOf(item.role) >= 0 || !item.role)
                    .reduce((o: any, item: any) => {
                        if (o.cGroup !== item.group) {
                            const group = MenuGroups.find(i => i.page === item.group)
                            if (group) {
                                o.cColor = group.color
                                o.items.push(<div key={`group_${item.group}`} className={'layout-menuside-group -' + o.cColor}>
                                    <Icon name={group.icon} className="_icon"/>
                                    <span className="_name">{group.name}</span>
                                </div>)
                            }
                            o.cGroup = item.group
                        }
                        o.items.push(<a key={`item_${item.group}-${item.page}`}
                                        href={!!item.url ? item.url : (!!item.group ? `/${item.group}/${item.page}` : `/${item.page}`)} onClick={e => menuClick(e, item)}
                                        className={clsNames('layout-menuside-item -' + o.cColor, cMenu === item.page && '-active')}>
                            {item.icon && <Icon name={item.icon} className="_icon" tooltip={miniSideMenu ? item.name : undefined}/>}
                            <span className="_name">{item.name}</span>
                        </a>)
                        return o
                    }, { items: [], cGroup: '', cColor: 'grey' }).items}
            </PageScroll>
        </div>

        <div className="btn-coll"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={toggleMiniSideMenu}/></div>
    </div>
}
