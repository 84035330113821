import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import L from '@loadable/component'

import { RouteNonuser, RoutePrivate } from 'unno-comutils/Route'
import Wait from 'unno-comutils/Wait'

const fallback = <Wait icon={'fan'}/>

const Login = L(() => import(/* webpackChunkName: "login" */'../login'), { fallback })
const Home = L(() => import(/* webpackChunkName: "home" */'../pages/office/server'), { fallback })

const OfficeWork = L(() => import(/* webpackChunkName: "project-task" */'../pages/office/work'), { fallback })
const OfficeCalendar = L(() => import(/* webpackChunkName: "calendar" */'../pages/office/calendar'), { fallback })
const OfficeDocument = L(() => import(/* webpackChunkName: "document" */'../pages/office/document'), { fallback })
const OfficeSnippet = L(() => import(/* webpackChunkName: "project-snippet" */'../pages/office/snippet'), { fallback })

const AccountSummary = L(() => import(/* webpackChunkName: "account-summary" */'../pages/account/summary'), { fallback })
const AccountQuotation = L(() => import(/* webpackChunkName: "account-quotation" */'../pages/account/quotation'), { fallback })
const AccountBill = L(() => import(/* webpackChunkName: "account-bill" */'../pages/account/bill'), { fallback })
const AccountBillExt = L(() => import(/* webpackChunkName: "account-billext" */'../pages/account/billExt'), { fallback })
const AccountPurchase = L(() => import(/* webpackChunkName: "account-purchase" */'../pages/account/purchase'), { fallback })
const AccountPartner = L(() => import(/* webpackChunkName: "account-partner" */'../pages/account/partner'), { fallback })
const AccountAsset = L(() => import(/* webpackChunkName: "account-asset" */'../pages/account/asset'), { fallback })
const AccountTeam = L(() => import(/* webpackChunkName: "account-team" */'../pages/account/team'), { fallback })

const Project = L(() => import(/* webpackChunkName: "project" */'../pages/office/project'), { fallback })
const ProjectDetail = L(() => import(/* webpackChunkName: "project" */'../pages/office/projectDetail'), { fallback })
const ProjectTicket = L(() => import(/* webpackChunkName: "project-ticket" */'../pages/office/ticket'), { fallback })

const ToolsGeolocation = L(() => import(/* webpackChunkName: "tool.geolocation" */'../pages/tools/geolocation'), { fallback })
const ToolsLang = L(() => import(/* webpackChunkName: "tool.lang" */'../pages/tools/lang'), { fallback })
const ToolsTrello = L(() => import(/* webpackChunkName: "tool.trello" */'../pages/tools/trello'), { fallback })
const ToolsIcon = L(() => import(/* webpackChunkName: "tool.icon" */'../pages/tools/icon'), { fallback })
const ToolsHeatmap = L(() => import(/* webpackChunkName: "tool.heatmap" */'../pages/tools/heatmap'), { fallback })
const ToolsMap = L(() => import(/* webpackChunkName: "tool.map" */'../pages/tools/map'), { fallback })
const ToolsDocker = L(() => import(/* webpackChunkName: "tool.docker" */'../pages/tools/docker'), { fallback })
const ToolsTemp = L(() => import(/* webpackChunkName: "tool.temp" */'../pages/tools/temp'), { fallback })

const ToolsDb = L(() => import(/* webpackChunkName: "tool.db" */'../pages/tools/database'), { fallback })
const ToolsEs = L(() => import(/* webpackChunkName: "tool.es" */'../pages/tools/elasticsearch'), { fallback })
const ToolsColor = L(() => import(/* webpackChunkName: "tool.color" */'../pages/tools/color'), { fallback })

const Unbbz = L(() => import(/* webpackChunkName: "document" */'../pages/service/unbbz'), { fallback })
const Unbus = L(() => import(/* webpackChunkName: "unbus" */'../pages/service/unbus'), { fallback })
const Line = L(() => import(/* webpackChunkName: "line" */'../pages/service/line'), { fallback })

export default function Router () {
    return <Switch>
        <Route exact path={['/', '/office', '/office/task']}><Redirect to="/office/work"/></Route>
        <RouteNonuser exact path="/login" children={<Login/>}/>

        <RoutePrivate exact path="/office/work" children={<OfficeWork/>}/>
        <RoutePrivate exact path="/office/server" children={<Home/>}/>
        <RoutePrivate exact path="/office/calendar" children={<OfficeCalendar/>}/>
        <RoutePrivate exact path="/office/project" children={<Project/>}/>
        <RoutePrivate exact path="/office/project/:id" children={<ProjectDetail/>}/>
        {/*<RoutePrivate exact path="/office/task_old" children={<ProjectTask/>}/>*/}
        <RoutePrivate exact path="/office/snippet" children={<OfficeSnippet/>}/>
        <RoutePrivate exact path="/office/ticket" children={<ProjectTicket/>}/>
        <RoutePrivate exact path="/office/document" children={<OfficeDocument/>}/>
        <RoutePrivate exact path="/office/document/:id" children={<OfficeDocument/>}/>

        <RoutePrivate exact path="/service/unbbz" children={<Unbbz/>}/>
        <RoutePrivate exact path={['/service/unbus', '/service/unbus/:page']} children={<Unbus/>}/>
        <RoutePrivate exact path={['/service/line', '/service/line/:page']} children={<Line/>}/>

        <RoutePrivate exact path="/account" needRole={'account'} children={<Redirect to="/account/summary"/>}/>
        <RoutePrivate exact path="/account/summary" needRole={'account'} children={<AccountSummary/>}/>
        <RoutePrivate exact path="/account/quotation" needRole={'account'} children={<AccountQuotation/>}/>
        <RoutePrivate exact path="/account/bill" needRole={'account'} children={<AccountBill/>}/>
        <RoutePrivate exact path="/account/bill_ext" needRole={'account'} children={<AccountBillExt/>}/>
        <RoutePrivate exact path="/account/purchase" needRole={'account'} children={<AccountPurchase/>}/>
        <RoutePrivate exact path="/account/partner" needRole={'account'} children={<AccountPartner/>}/>
        <RoutePrivate exact path="/account/asset" needRole={'account'} children={<AccountAsset/>}/>
        <RoutePrivate exact path={['/account/team', '/office/salary']} children={<AccountTeam/>}/>

        <RoutePrivate exact path="/tools/docker" children={<ToolsDocker/>}/>
        <RoutePrivate path="/tools/geolocation" children={<ToolsGeolocation/>}/>
        <RoutePrivate path="/tools/trello" children={<ToolsTrello/>}/>
        <RoutePrivate exact path="/tools/heatmap" children={<ToolsHeatmap/>}/>
        <RoutePrivate exact path="/tools/lang" children={<ToolsLang/>}/>
        <RoutePrivate exact path="/tools/map" children={<ToolsMap/>}/>
        <RoutePrivate exact path="/tools/temp" children={<ToolsTemp/>}/>
        <RoutePrivate exact path="/tools/icon" children={<ToolsIcon/>}/>

        <RoutePrivate exact path={['/tools/db', '/tools/db/:page']} children={<ToolsDb/>}/>
        <RoutePrivate exact path={['/tools/es', '/tools/es/:page']} children={<ToolsEs/>}/>
        <RoutePrivate exact path="/tools/color" children={<ToolsColor/>}/>
    </Switch>
}
