export const MenuGroups = [
    { page: 'office', name: 'Office', icon: 'pizza', color: 'blue', role: '' },
    { page: 'tools', name: 'Tools', icon: 'soup', color: 'green', role: '' },
    { page: 'service', name: 'Service', icon: 'cheeseburger', color: 'orange', role: '' },
    { page: 'account', name: 'Fin', icon: 'apple-crate', color: 'red', role: 'account' },
    //{ page: 'tool', name: 'เครื่องมือ', icon: 'candy-cane', color: 'purple', role: '' },
]

export const Menus = [
    { group: 'office', page: 'work', name: 'งาน', icon: 'chart-network', role: '' },
    //{ group: 'office', page: 'server', name: 'สถานะการณ์', icon: 'cloud-showers', role: '' },
    //{ group: 'office', page: 'calendar', name: 'ปฏิทิน', icon: 'calendar-day', role: '' },
    //{ group: 'office', page: 'project', name: 'โครงการ', icon: 'scroll-old', role: '' },
    //{ group: 'office', page: 'task', name: 'ตารางงาน', icon: 'tasks', role: '' },
    //{ group: 'office', page: 'snippet', name: 'โน๊ตงาน', icon: 'sticky-note', role: '' },
    //{ group: 'office', page: 'ticket', name: 'แจ้งปัญหา', icon: 'tags', role: '' },
    { group: 'office', page: 'document', name: 'เอกสาร', icon: 'book-dead', role: '' },
    { group: 'office', page: 'salary', name: 'เงินเดือน', icon: 'gift-card', role: '' },

    // ---------

    { group: 'tools', page: 'docker', name: 'Docker', icon: 'whale', role: '' },
    { group: 'tools', page: 'db', name: 'Database', icon: 'database', role: '' },
    { group: 'tools', page: 'es', name: 'Elasticsearch', icon: 'search', role: '' },
    { group: 'tools', page: 'geolocation', name: 'GeoLocation', icon: 'map-marked-alt', role: '' },
    { group: 'tools', page: 'lang', name: 'แปลภาษา', icon: 'language', role: '' },

    { group: 'tools', page: 'icon', name: 'ไอคอน', icon: 'icons', role: '' },
    { group: 'tools', page: 'color', name: 'ตารางสี', icon: 'palette', role: '' },
    //{ group: 'tools', page: 'map', name: 'Map', icon: 'map', role: '' },
    //{ group: 'tools', page: 'trello', name: 'Trello', icon: 'comet', role: '' },
    //{ group: 'tools', page: 'db_schema', name: 'โครงสร้างตาราง', icon: 'database', role: '' },
    //{ group: 'tools', page: 'db_column', name: 'ตรวจสอบคอลัมน์', icon: 'file-search', role: '' },
    //{ group: 'tools', page: 'db_compare', name: 'เทียบตาราง', icon: 'compress-alt', role: '' },
    { group: 'tools', page: 'lab', name: 'Lab', icon: 'flask', role: '' },

    // ---------

    { group: 'service', page: 'line', name: 'ระบบแชท', icon: 'comment-smile', role: '' },
    /*
    { group: 'service', page: 'domain', name: 'จดโดเมน', icon: 'globe-asia', role: '' },
    { group: 'service', page: 'unrai', name: 'ระบบเว็บสำเร็จรูป', icon: 'cloud-meatball', role: '' },
    { group: 'service', page: 'unbus', name: 'ระบบขายตั๋ว', icon: 'steak', role: '' },
    { group: 'service', page: 'uncup', name: 'ระบบสมาชิกสหกรณ์', icon: 'wheat', role: '' },
    { group: 'service', page: 'unbbz', name: 'ระบบเว็บสำเร็จรูป (เก่า)', icon: 'sunset', role: '' },
    */

    // ---------

    { group: 'account', page: 'summary', name: 'สรุปบัญชี', icon: 'sigma', role: 'account' },
    //{ group: 'account', page: 'quotation', name: 'ใบเสนอราคา', icon: 'comment-dollar', role: 'account' },
    { group: 'account', page: 'bill', name: 'บิล/ใบเสร็จ', icon: 'file-invoice-dollar', role: 'account' },
    { group: 'account', page: 'bill_ext', name: 'วางบิลนอก', icon: 'money-check-alt', role: 'account' },
    { group: 'account', page: 'purchase', name: 'บันทึกการซื้อ', icon: 'badge-dollar', role: 'account' },
    { group: 'account', page: 'team', name: 'เงินเดือน', icon: 'gift-card', role: 'account' },
    //{ group: 'account', page: 'asset', name: 'พัสดุ', icon: 'box', role: 'account' },
    { group: 'account', page: 'partner', name: 'คู่ค้า', icon: 'user-cowboy', role: 'account' },
    //{ group: 'account', page: 'doc_tax50', name: 'หัก ณ ที่จ่าย', icon: 'receipt', role: 'account' },
]
